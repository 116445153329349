// Types of the backend API
type ObjectId = string;

export enum SupportedLanguage {
    fr = 'fr',
    // en = 'en',
    // es = 'es',
    // du = 'du',
    // it = 'it',
    // de = 'de',
    // ca = 'ca',
    // po = 'po',
    // ru = 'ru',
    // pt = 'pt',
    // tr = 'tr',
    // us = 'us',
    // da = 'da',
    // hu = 'hu',
    // zh = 'zh',
    // ko = 'ko',
    // sv = 'sv',
    // cs = 'cs',
    // no = 'no',
    // br = 'br',
    // ja = 'ja',
}

export enum DeviceType {
    ios = 'ios',
    android = 'android',
    web = 'web',
    backoffice = 'backoffice',
    api = 'api',
    nothing = '',
}

export interface Organization {
    id: string;
    name: string;
    slug: string;
    reference: string;
    siret?: string;
    address?: Address;
    applications?: Application[];
    hasVerifiedData?: boolean;
    hasBeenSeen: boolean;
    parent?: Organization;
    children?: Organization[];
    accountingEmails?: string[];
    computedProperties?: {
        penaltiesCount?: number;
    };
    createdAt: string;
    updatedAt: string;
    logoUrl?: string;
    hub?: Organization;
}

export enum NotificationStatus {
    pending = 'pending', // not useful here
    sent = 'sent', // not useful here
    received = 'received',
    opened = 'opened',
    failed = 'failed', // not useful here
}

export enum NotificationContextType {
    newAppointment = 'newAppointment',
    appointmentAccepted = 'appointmentAccepted',
    appointmentRefused = 'appointmentRefused',
    appointmentCanceled = 'appointmentCanceled',
    appointmentSigned = 'appointmentSigned',
    appointmentNotSigned = 'appointmentNotSigned',
    newProforma = 'newProforma',
    contestAutoAccepted = 'contestAutoAccepted',
    contestAccepted = 'contestAccepted',
    contestRefused = 'contestRefused',
    documentsDeleted = 'documentsDeleted',
    documentsAdded = 'documentsAdded',
}

export interface Notification {
    id: ObjectId;
    type: 'text';
    channel: 'pull';
    payload: {
        text: string;
        context?: {
            type: NotificationContextType;
            penaltyId?: Penalty['id'];
            commentId?: Comment['id'];
            appointmentId?: Appointment['id'];
            appointmentFromDate?: Appointment['fromDate'];
            [key: string]: any;
        };
    };
    reference: string;
    status: NotificationStatus;
    sendAt: string;
}

export interface Address {
    id?: ObjectId;
    number?: string;
    street?: string;
    zipCode?: string;
    city?: string;
    region?: string;
    country?: string;
    locality?: string;
    sup1?: string;
    sup2?: string;
    directives?: string;
    floor?: string;
    elevator?: boolean;
    intercom?: boolean;
    digiCode?: string;
    codeIris?: string;
    codeInsee?: string;
    distanceToParkingSpot?: number;
    coordinates?: Coordinates;
    coordinatesRadius?: number;
}

export interface Coordinates {
    id?: ObjectId;
    latitude: number;
    longitude: number;
}

export enum PermissionRight {
    read = 'r',
    write = 'w',
    disabled = 'disabled',
}

export enum Permission {
    superAdmin = 'superAdmin',
    admin = 'admin',
    logAs = 'logAs',
    users = 'users',
    roles = 'roles',
    organizations = 'organizations',
    staticPages = 'staticPages',
    questions = 'questions',
    appointments = 'appointments',
    backOffice = 'backOffice',
}

export enum RoleScope {
    organizations = 'organizations',
    users = 'users',
    groups = 'groups',
}

export interface Scope {
    organizations: ObjectId[];
    users: ObjectId[];
}
export interface Role {
    id: ObjectId;
    name: string;
    slug: RoleSlug;
    removable?: boolean;
    permissions?: {
        [key in Permission]?: PermissionRight;
    };
    scope?: {
        [key in RoleScope]?: boolean;
    };
}
export enum RoleSlug {
    superAdmin = 'superAdmin',
    owner = 'owner',
    admin = 'admin',
    user = 'user',
    providerAdmin = 'providerAdmin',
    providerUser = 'providerUser',
}

export interface UserScope {
    // [key in RoleScope]?: ObjectId[];
    [RoleScope.organizations]?: Organization[];
    [RoleScope.users]?: User[];
}
export interface User {
    id: ObjectId;
    createdAt?: string;
    updatedAt?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    role?: Role;
    permissions?: {
        [key in Permission]?: PermissionRight;
    };
    mergedPermissions?: {
        [key in Permission]?: PermissionRight;
    };
    scope?: UserScope;
    organization?: Organization;
    organizations?: Organization[];
    hubs?: Organization[];
    acceptedTerms?: boolean;
    hasVerifiedData?: boolean;
    hasSeenTutorial?: boolean;
    job?: string;
}

export interface AuthResponse {
    user: User;
    authToken: string;
    refreshToken: string;
}

export interface ListResponse<T> {
    items: T[];
    totalCount: number;
    page: number;
    pageSize: number;
    pageCount: number;
}

export interface Application {
    id: string;
    name: string;
    organization?: Organization;
    applicationClients?: ApplicationClient[];
}

export interface ApplicationClient {
    id: string;
    name: string;
    apiKey: string;
    isPrivate: boolean;
    reference: string;
    type: DeviceType;
    options: {
        isBackoffice?: boolean;
    };
    iosOptions?: {
        authentificationPush: string;
        keyId: string;
        teamId: string;
        bundleId: string;
        stagingServer: boolean;
    };
    androidOptions?: {
        certificatPush: string;
        bundleId: string;
    };
    application?: Application;
    organization?: Organization;
}

export interface Setting {
    name: string;
    slug: SettingSlug;
    value?: string | number;
    minValue?: number;
    maxValue?: number;
    valueType: SettingValueType;
    unit?: SettingUnit;
    enabled: boolean;
}

export enum SettingSlug {
    notContestedAfter = 'notContestedAfter',
    toInvoiceAfter = 'toInvoiceAfter',
    highlightPendingOrInProgressAfter = 'highlightPendingOrInProgressAfter',
    noAppointmentReportReminderAfter = 'noAppointmentReportReminderAfter',
    appointmentDuration = 'appointmentDuration',
    appointmentStartTime = 'appointmentStartTime',
    appointmentEndTime = 'appointmentEndTime',
    appointmentDays = 'appointmentDays',
}

export enum SettingValueType {
    weekday = 'weekday',
    string = 'string',
    number = 'number',
}

export enum ValueListFieldValueType {
    weekday = 'weekday',
    string = 'string',
    number = 'number',
}

export enum SettingUnit {
    second = 'second',
    minute = 'minute',
    hour = 'hour',
    day = 'day',
    week = 'week',
    month = 'month',
    year = 'year',
}

export enum ValueListFieldUnit {
    second = 'second',
    minute = 'minute',
    hour = 'hour',
    day = 'day',
    week = 'week',
    month = 'month',
    year = 'year',
}

export interface ValueListField {
    id: string;
    title: string;
    columnTitle?: string;
    isSortable?: boolean;
    sortKey?: string;
    name: string;
    valueType: ValueListFieldValueType;
    unit?: ValueListFieldUnit;
    minValue?: number;
    maxValue?: number;
    isEnabled: boolean;
    isRequired?: boolean;
}
export interface ValueList {
    id: string;
    title: string;
    slug: string;
    fields: ValueListField[];
    meta: {
        [key: string]: any;
    };
}
export interface ValueListItem {
    id: string;
    fields: {
        [key: string]: string | number;
    };
}

export enum ValueListSlug {
    messageTemplates = 'message-templates',
    gt = 'gt',
    penaltyTypes = 'penaltyTypes',
}

export interface RemoteFile {
    id: string;
    originalName: string;
    mimeType: string;
    url: string;
    thumbnailUrl?: string;
    name?: string;
}

export interface Order {
    reference?: string;
    date?: string;
    plannedDeliveryDate?: string;
    deliveryDate?: string;
    deliveryNumber?: string;
}

export interface PenaltyInvoice {
    reference?: string;
    type?: string;
    statusRaw?: string;
    status?: PenaltyInvoiceStatus;
    currency?: string;
    outdated?: boolean;
    amount?: {
        total: number;
        partialDiscount: number;
        canceled: number;
        reimbursed: number;
        kept: number;
    };
}

export interface Penalty {
    id: string;
    reference: string;
    status: PenaltyStatus;
    contestationStatus: PenaltyContestationStatus;
    invoiceStatus?: PenaltyInvoiceStatus;
    appointmentStatus?: AppointmentStatus;
    type: string;
    orders?: Order[];
    amount: number;
    originalAmount: number;
    currency: string;
    organization: Organization;
    date: string;
    createdAt: string;
    updatedAt: string;
    attachments?: RemoteFile[];
    justifications?: RemoteFile[];
    priceJustifications?: RemoteFile[];
    invoices?: RemoteFile[];
    invoice?: PenaltyInvoice;
    proforma?: RemoteFile;
    acceptedBy?: User;
    acceptedAt?: string;
    contestedAt?: string;
    lastViewedAt?: string;
    firstViewedAt?: string;
    firstViewedBy?: User;
    comments?: Comment[];
    gt?: string;
    fees?: Fee[]; // filtered out "cql: true"-fee in query select
    cqlFee?: Fee; // extracted from fees in query select, doesn't exist server side
    computedProperties?: PenaltyComputedProperties;
    reviewing?: User;
}

export interface PenaltyComputedProperties {
    pendingAmount: number;
    acceptedAmount: number;
    contestedAmount: number;
    contestationAcceptedAmount: number;
    contestationRejectedAmount: number;
    allContestedAmount: number;
    finalAmount: number;
    reimbursedAmount: number;
    allCommentsReadByAdmin: boolean;
    allCommentsReadByUser: boolean;
    typeName: string;
}

export interface Comment {
    id: string;
    content: string;
    user: User;
    createdAt: string;
    type: CommentType;
    attachments?: RemoteFile[];
    readByAdmin?: string;
    readByUser?: string;
}

export enum CommentType {
    contestation = 'contestation',
    changeAmount = 'changeAmount',
    acceptContestation = 'acceptContestation',
    changeStatus = 'changeStatus',
}

export enum PenaltyInvoiceStatus {
    pending = 'pending',
    toReimburse = 'toReimburse',
    toInvoice = 'toInvoice',
    toInvoiceWithStoreCredit = 'toInvoiceWithStoreCredit',
    reimbursed = 'reimbursed',
}

export enum PenaltyStatus {
    pending = 'pending',
    received = 'received',
    notContested = 'notContested',
    accepted = 'accepted',
    contested = 'contested',
    contestedPartially = 'contestedPartially',
    contestationAccepted = 'contestationAccepted',
    waitingForValidation = 'waitingForValidation',
    toCheckAfterMeeting = 'toCheckAfterMeeting',
    closedToReimburseAfterMeeting = 'closedToReimburseAfterMeeting',
    closedAfterMeeting = 'closedAfterMeeting',
    closedReimbursed = 'closedReimbursed',
}

export enum PenaltyContestationStatus {
    pending = 'pending',
    accepted = 'accepted',
    contested = 'contested',
    contestedPartially = 'contestedPartially',
    contestationAccepted = 'contestationAccepted',
    contestationPartiallyAccepted = 'contestationPartiallyAccepted',
    contestationRejected = 'contestationRejected',
    waitingForProvider = 'waitingForProvider',
}

export enum PenaltyUpdateAction {
    contestation = 'contestation',
    statusChange = 'statusChange',
}

export enum AvailabilityRecurrence {
    none = 'none',
    daily = 'daily',
    weekly = 'weekly',
    monthly = 'monthly',
    yearly = 'yearly',
}

export enum AppointmentStatus {
    pending = 'pending',
    canceled = 'canceled',
    accepted = 'accepted',
    refused = 'refused',
    inProgress = 'inProgress',
    done = 'done',
    notSigned = 'notSigned',
    signed = 'signed',
}

export interface AvailableAppointments {
    duration: number;
    timeSlots: string[];
}

export interface AppointmentAvailability {
    id: string;
    title: string;
    fromDate: string;
    toDate: string;
    isFullDay: boolean;
    recurrence: AvailabilityRecurrence;
    recurrenceUntilDate?: string;
}

export interface Appointment {
    id: string;
    hub?: Organization;
    organization?: Organization;
    createdBy?: User;
    createdByAdmin: boolean;
    acceptedBy?: User;
    refusedBy?: User;
    fromDate: string;
    toDate: string;
    comment?: string;
    status: AppointmentStatus;
    reportStatus: AppointmentReportStatus;
    reports?: AppointmentReport[];
    notes?: AppointmentNotes;
    isNotificationClose?: boolean;
    hubNotification?: boolean;
}

export interface AppointmentSlot {
    id: string;
    isAvailable: boolean;
    fromDate: string;
    toDate: string;
}

export interface AppointmentReport {
    hubUsers?: User[];
    providerUsers?: User[];
    extraHubRepresentatives: Array<{
        firstName?: string;
        lastName?: string;
    }>;
    extraProviderRepresentatives: Array<{
        firstName?: string;
        lastName?: string;
    }>;
    content: string;
    providerContent: string;
    status: AppointmentReportStatus;
    comment?: string;
    progressPlan?: string;
    createdAt: string;
    updatedAt: string;
    signedAt?: string;
    attachments: RemoteFile[];
    providerAttachments: RemoteFile[];
    reportFile?: RemoteFile;
    penalty?: {
        amount?: number;
        fromDate?: string;
        toDate?: string;
    };
}

export enum AppointmentReportStatus {
    pending = 'pending',
    inProgress = 'inProgress',
    signed = 'signed',
    refused = 'refused',
    waitingForValidation = 'waitingForValidation',
}

export interface AppointmentNotes {
    comment: string;
    attachments?: RemoteFile[];
}

export interface Fee {
    id: string;
    description: string;
    quantity: number;
    pricePerUnit: number;
    amount: number;
    originalAmount: number;
    order?: Order['reference'];
    deliveryNumber?: Order['deliveryNumber'];
    cql: boolean;
    status: FeeStatus;
    statusHistory?: [
        // admin decisions only
        FeeStatus.contestationAccepted | FeeStatus.contestationRejected | undefined,
        FeeStatus.contestationAccepted | FeeStatus.contestationRejected | undefined
    ];
}

export enum FeeStatus {
    pending = 'pending',
    contested = 'contested',
    accepted = 'accepted',
    contestationAccepted = 'contestationAccepted',
    contestationRejected = 'contestationRejected',
    waitingForProvider = 'waitingForProvider',
}
